import {
  signInWithEmailAndPassword,
  getAuth,
  setPersistence,
  browserSessionPersistence,
  signOut,
} from 'firebase/auth';
import { onSnapshot, where, collection, query } from 'firebase/firestore';
import { db } from '@/db/firebase';
import User from '@/class/User';

import Web3 from 'web3';
new Web3(Web3.givenProvider);
import { ManagerRequestAddress, ManagerRequestAbi, } from './contracts';
//GovernanceTokenAddress, GovernanceTokenAbi

const state = {
  user: null,
  userUnsubscribe: null,
  wallet: {
    balance: 0,
  },
  connectedWeb3: false,
  walletWeb3Address: "",
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setWallet(state, wallet) {
    state.wallet = wallet;
  },
  setUserUnsubscribe(state, userUnsubscribe) {
    state.userUnsubscribe = userUnsubscribe;
  },
  setConnectedWeb3(state, { status, address }) {
    state.connectedWeb3 = status;
    state.walletWeb3Address = address;
  }
};

const getters = {
  getUser(state) {
    return state.user;
  },
  getUserRole(state) {
    if (!state.user) return null;
    return state.user.role || null;
  },
  getWallet(state) {
    return state.wallet;
  },
  getConnectedWeb3(state) {
    return state.connectedWeb3;
  },
  getConnectWeb3Address(state) {
    return state.walletWeb3Address;
  }
};

const actions = {
  // LOGIN/REGITER
  login({ dispatch }, { username, password }) {
    const auth = getAuth();
    return setPersistence(auth, browserSessionPersistence)
      .then(() => {
        return signInWithEmailAndPassword(auth, username, password).then(
          async (userCredential) => {
            // Signed in
            await dispatch('getUserData', userCredential.user);
            return true;
          },
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.error(errorCode, errorMessage);
        throw new Error(error);
      });
  },
  logout({ commit }) {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        commit('setUser', null);
        commit('setExpandRightMenu', false, { root: true });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  async getUserData({ commit, dispatch }, user) {
    if (!user.email) return;
    const auth = getAuth();
    const userAuth = auth.currentUser;

    const userRef = collection(db, 'users');
    const q = query(userRef, where('username', '==', user.email));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      querySnapshot.forEach(async (doc) => {
        let userConnect = doc.data();
        let id = doc.id;
        let user = new User({ id: id });
        await user.getUser();
        commit('setUser', {
          id: id,
          name: userConnect.firstName,
          username: userConnect.username,
          role: userConnect.role,
          emailVerified: userAuth.emailVerified,
          ...userConnect,
        });
        dispatch('startUserSession', doc.id);
        dispatch('startUserWalletSession', doc.id);
      });
    });

    // Mantenha uma referência para o listener para que você possa cancelá-lo posteriormente, se necessário
    // Por exemplo: unsubscribe();
    // Retorne a função de cancelamento do listener, se desejar
    return unsubscribe;
  },
  async startUserSession({ commit }, userId) {
    if (!userId) return;
    let user = new User({ id: userId });
    await user.getUser();
    const auth = getAuth();
    const userAuth = auth.currentUser;

    const userRef = collection(db, 'users');
    const q = query(userRef, where('username', '==', user.email));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let userConnect = change.doc.data();

        if (change.type === 'modified') {
          commit('setUser', {
            id: change.doc.id,
            name: userConnect.firstName,
            username: userConnect.username,
            role: userConnect.role,
            emailVerified: userAuth.emailVerified,
            ...userConnect,
          });
        }
        if (change.type === 'removed') {
          commit('setUser', null);
          commit('setUserUnsubscribe', null);
        }
      });
    });
    commit('setUserUnsubscribe', unsubscribe);
  },
  async startUserWalletSession({ commit }, userId) {
    const userRef = collection(db, `users/${userId}/wallets`);
    const q = query(userRef);
    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let wallet = change.doc.data();

        if (change.type === 'modified' || change.type === 'added') {
          commit('setWallet', {
            ...wallet,
          });
        }
        if (change.type === 'removed') {
          commit('setWallet', null);
        }
      });
    });
    commit('setUserUnsubscribe', unsubscribe);
  },
  async connectedWeb3({ commit }) {
    if (window.ethereum) { // first we check if metamask is installed
      window.ethereum.request({ method: 'eth_requestAccounts' })
        .then((r) => {
          console.log("🚀 ~ file: user.js:178 ~ .then ~ r:", r)
          commit('setConnectedWeb3', { status: true, address: r[0] });
        });
    }
  },
  createRequest() {
    // Conectar-se ao provider fornecido pelo MetaMask
    const provider = window.ethereum;

    // Inicializar o objeto Web3 usando o provider do MetaMask
    const web3 = new Web3(provider);
    // Criar instância do contrato
    const managerRequestContract = new web3.eth.Contract(ManagerRequestAbi, ManagerRequestAddress);

    // // Detalhes do pedido a ser criado
    // const products = ["Product1", "Product2"]; // Substitua com os produtos reais
    // const quantities = [2, 3]; // Substitua com as quantidades reais
    // const values = [100, 150]; // Substitua com os valores reais


    // Obter contas do MetaMask
    web3.eth.getAccounts()
      .then(async (accounts) => {
        const fromAccount = accounts[0];

        const spenderAddress = ManagerRequestAddress;
        const fee = await managerRequestContract.methods.tokenFee().call({ from: fromAccount });
        console.log("🚀 ~ file: user.js:208 ~ .then ~ spenderAddress:", fromAccount, spenderAddress, fee)

        // // Aprovar a transferência de tokens pelo contrato Store
        // const approveTx = await tokenContract.methods.approve(spenderAddress, fee + calculateTotalValue(products, quantities)).send({
        //   from: fromAccount,
        //   gas: 200000, // Substitua com o limite de gás apropriado
        // });

        // // Chamar a função createRequest no contrato
        // managerRequestContract.methods.createRequest(products, quantities, values)
        //   .send({
        //     from: fromAccount,
        //     gas: 2000000, // Substitua com o limite de gás apropriado
        //   })
        //   .on('transactionHash', (hash) => {
        //     console.log('Transação enviada. Hash:', hash);
        //   })
        //   .on('confirmation', (confirmationNumber, receipt) => {
        //     if (confirmationNumber === 1) {
        //       console.log('Transação confirmada. Receipt:', receipt);
        //     }
        //   })
        //   .on('error', (error) => {
        //     console.error('Erro ao enviar a transação:', error);
        //   });
      })
      .catch((error) => {
        console.error('Erro ao obter contas do MetaMask:', error);
      });
  }
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
