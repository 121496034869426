import { createStore } from 'vuex';
import adminModule from './modules/admin';
import userModule from './modules/user';
import postModules from './modules/posts';
import forumModule from './modules/forum';
import categoriesModule from './modules/categories';
import productsModule from './modules/products';

// import shoppingModule from './modules/Edit/shopping';
// import addressModule from './modules/address';
// import creditCardModule from './modules/Edit/creditCard';
// import chatbot from './modules/Edit/chatbot';

// Create a new store instance.
export const store = createStore({
  state: {
    toast: null,
    formStartNow: false,
    expandLeftMenu: false,
    expandRightMenu: false,
    showCheckout: false,
    showLoginModal: false,
  },
  mutations: {
    setToast(state, toast) {
      state.toast = toast;
    },
    setFormStartNow(state) {
      state.formStartNow = !state.formStartNow;
    },
    setExpandLeftMenu(state) {
      state.expandLeftMenu = !state.expandLeftMenu;
    },
    setExpandRightMenu(state, forceState = null) {
      state.expandRightMenu = forceState || !state.expandRightMenu;
    },
    setShowCheckout(state) {
      state.showCheckout = !state.showCheckout;
    },
    setShowLoginModal(state) {
      state.showLoginModal = !state.showLoginModal;
    },
  },
  getters: {
    getToast(state) {
      return state.toast;
    },
    getFormStartNow(state) {
      return state.formStartNow;
    },
    getExpandLeftMenu(state) {
      return state.expandLeftMenu;
    },
    getExpandRightMenu(state) {
      return state.expandRightMenu;
    },
    getShowCheckout(state) {
      return state.showCheckout;
    },
    getShowLoginModal(state) {
      return state.showLoginModal;
    },
  },
  actions: {
    setToast({ commit }, { error, description }) {
      commit('setToast', { error, description });
    },
    setFormStartNow({ commit }) {
      commit('setFormStartNow');
    },
    expandLeftMenu({ commit, state }) {
      commit('setExpandLeftMenu');
      if (state.expandRightMenu) commit('setExpandRightMenu');
    },
    expandRightMenu({ commit, state }) {
      commit('setExpandRightMenu');
      if (state.expandLeftMenu) commit('setExpandLeftMenu');
    },
    showCheckout({ commit }) {
      commit('setShowCheckout');
    },
    openLoginModal({ commit }) {
      commit('setShowLoginModal');
    },
  },
  modules: {
    user: userModule,
    posts: postModules,
    admin: adminModule,
    forum: forumModule,
    categories: categoriesModule,
    products: productsModule,
    // shopping: shoppingModule,
    // address: addressModule,
    // creditCard: creditCardModule,
    // chatbot: chatbot,
  },
});
