import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "@/db/firebase";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import api from '@/config/api';


const state = {
    allProducts: [],
    downloadImageBuffer: null,
    percentage: null,
};

const mutations = {
    setAllProducts(state, allProducts) {
        state.allProducts = allProducts;
    },
    addProduct(state, data) {
        state.allProducts.push(data);
    },
    updateProduct(state, data) {
        const productIndex = state.allProducts.findIndex((product) => product.id === data.id);
        state.allProducts[productIndex] = data;
    },
    deleteProduct(state, data) {
        const productIndex = state.allProducts.findIndex((product) => product.id === data.id);
        state.allProducts.splice(productIndex, 1);
    },
    setPercentageÌmageSend(state, percentage) {
        state.percentage = percentage;
    },
    setDownloadImageBuffer(state, downloadLink) {
        state.downloadImageBuffer = downloadLink;
    }
};

const getters = {
    getAllProducts(state) {
        return state.allProducts;
    },
    getDownloadImage(state) {
        return state.downloadImageBuffer;
    },
    getPercentageImage(state) {
        return String(state.percentage);
    }
};

const actions = {
    async startListenerAllProducts({ commit }) {
        commit('setAllProducts', []);
        const q = query(collection(db, "products"));
        onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                let doc = change.doc.data();
                doc.id = change.doc.id;
                if (change.type === "added") {
                    commit('addProduct', doc);
                }
                if (change.type === "modified") {
                    commit('updateProduct', doc);
                }
                if (change.type === "removed") {
                    commit('deleteProduct', doc);
                }
            });
        });
    },
    async sendProductImage({ commit }, file) {
        if (!file) return;
        const storage = getStorage();
        let idImageRef = String(new Date().getTime()) + '.jpg';
        let path = 'products/' + idImageRef
        const productImagesRef = ref(storage, path);
        const uploadTask = uploadBytesResumable(productImagesRef, file);
        uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                commit('setPercentageÌmageSend', progress);
                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                        break;
                    case 'running':
                        console.log('Upload is running');
                        break;
                }
            },
            (error) => {
                // Handle unsuccessful uploads
                throw new Error(error);
            },
            async () => {
                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                await getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                    commit('setDownloadImageBuffer', downloadURL);
                });
            }
        );

    },
    async addProduct(_, { name, value, categorie_id }) {
        console.log("🚀 ~ file: products.js:105 ~ addProduct ~ name, value, categorie_id:", name, value, categorie_id)
        try {
            return await api.post('user-addProduct', {
                name,
                value,
                categorie: categorie_id
            })
        } catch (e) {
            throw new Error("Erro ao adicionar produto. Tente novamente mais tarde.");
        }
    },
    async removeProduct(_, id) {
        try {
            return await api.post('user-removeProduct', {
                id
            })
        } catch (e) {
            throw new Error("Erro ao adicionar categoria. Tente novamente mais tarde.");
        }
    }
}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
