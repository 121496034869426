import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '@/db/firebase';
import { doc, setDoc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';

class User {
  constructor({
    firstName = '',
    lastName = '',
    email = '',
    phone = '',
    numEmployees = '',
    role = null,
  }) {
    this.id = '';
    this.username = email;
    this.firstName = firstName;
    this.lastName = lastName || '';
    this.email = email;
    this.phone = phone;
    this.numEmployees = numEmployees || '0';
    this.role = role;
  }

  async addUserFirebase(password) {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      this.username,
      password,
    );
    const user = userCredential.user;

    // Adicione os dados do usuário ao Firestore
    this.id = user.uid;
    let userDoc = await setDoc(doc(db, 'users', user.uid), { ...this });

    // Cria uma carteira para o usuário
    await setDoc(doc(db, `users/${user.uid}/wallets`, 'main'), {
      balance: 0,
      staking: 0,
    });

    return userDoc;
  }

  async updateUserFirebase() {
    return await updateDoc(doc(db, 'users', this.id), { ...this });
  }

  async deleteUserFirebase() {
    return await deleteDoc(doc(db, 'users', this.id));
  }

  async addUserRole(role) {
    return await updateDoc(doc(db, 'users', this.id), { ...this, role: role });
  }

  async updateUserBalance(quantity) {
    this.balance += quantity;
  }

  async getUser(id = null) {
    if (!id) return;
    let user = await getDoc(doc(db, 'users', this.id || id));

    let data = user.data();
    this.id = id;
    this.username = data.username;
    this.firstName = data.firstName;
    this.lastName = data.lastName || '';
    this.email = data.email;
    this.phone = data.phone;
    this.numEmployees = data.numEmployees || '0';
    this.role = data.role;
  }
}

export default User;
