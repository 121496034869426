import { collection, query, onSnapshot } from 'firebase/firestore';
import { db } from '@/db/firebase';

const state = {
  allTopics: [],
};

const mutations = {
  resetTopics(state) {
    state.allTopics = [];
  },
  addTopic(state, data) {
    state.allTopics.push(data);
  },
  updateTopic(state, data) {
    const topicIndex = state.allTopics.findIndex(
      (topic) => topic.id === data.id,
    );
    state.allTopics[topicIndex] = data;
  },
  deleteTopic(state, data) {
    const topicIndex = state.allTopics.findIndex(
      (topic) => topic.id === data.id,
    );
    state.allTopics.splice(topicIndex, 1);
  },
};

const getters = {
  getAllTopics(state) {
    return state.allTopics;
  },
};

const actions = {
  async getAllTopics({ commit }) {
    commit('resetTopics');
    const q = query(collection(db, 'forum'));
    onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        let doc = change.doc.data();
        doc.id = change.doc.id;
        if (change.type === 'added') {
          commit('addTopic', doc);
        }
        if (change.type === 'modified') {
          commit('updateTopic', doc);
        }
        if (change.type === 'removed') {
          commit('deleteTopic', doc);
        }
      });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
  namespaced: true,
};
