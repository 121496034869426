import axios from 'axios';

// Crie uma instância do Axios
const api = axios.create({
  //baseURL: 'https://us-central1-webstore-ea2cb.cloudfunctions.net/', // Substitua pela sua URL base
  baseURL: 'https://us-central1-webstore-ea2cb.cloudfunctions.net',
  timeout: 10000, // Defina um timeout (opcional)
  // headers: {
  //   // Você pode definir cabeçalhos padrão, se necessário
  //   // Authorization: 'Bearer seu-token-de-autenticacao',
  //   // 'Content-Type': 'application/json',
  // },
});

export default api;