<template>
  <div id="app">
    <router-view></router-view>
    <Toast />
    <Checkout v-if="getShowCheckout" />
    <ChatbotComponent v-if="false" />
    <!-- <Assisent v-if="false" /> -->
  </div>
</template>

<script>
// import Assisent from "@/components/Assistent/Assistent.vue";
import Toast from "@/components/utils/Toast.vue";
import Checkout from "@/components/App/Content/Pages/Checkout.vue";
import ChatbotComponent from "@/components/Assistent/Chatbot.vue";
export default {
  name: "App",
  components: {
    Toast,
    Checkout,
    ChatbotComponent
    // Assisent,
  },
  data(){
    return{
    }
  },
  computed: {
    getShowCheckout() {
      if (this.$store) {
        return this.$store.getters['getShowCheckout'];
      } else {
        return false;
      }

    }
  }
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  background-color: #fff;

  div {
    // border: 1px solid red !important; 
    box-sizing: border-box !important;
  }

  &::-webkit-scrollbar {
    width: 15px; /* Largura da barra de rolagem */
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgray; /* Cor do "polegar" da barra de rolagem */
  }

}

.modal-backdrop {
  display: none;
}


@media screen and (max-width: 1000px) {}
</style>
