import {db} from "@/db/firebase";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import Post from '@/class/Post';


const state = {
    lastReleases: [],
    servicesPosts: [],
}

const getters = {
    getLastReleases(state) {
        return state.lastReleases;
    },
    getServicesPosts(state) {
        return state.servicesPosts;
    }
}

const mutations = {
    setLastReleases(state, posts) {
        state.lastReleases = posts;
    },
    setServicesPosts(state, posts) {
        state.servicesPosts = posts;
    }
}

const actions = {
    afterUpdate({dispatch}){
        dispatch('getLastReleases') // Atualiza a lista de recentes
        dispatch('getServicesPosts') // Atualiza a lista de recentes
    },
    async publishPost({dispatch}, {title=null, content=null, collection=null}){
        try{
            const newPost = new Post({title, content, createdAt: new Date().getTime(), updatedAt: new Date().getTime(), collection: collection});
            newPost.addPostFirebase();
            dispatch('afterUpdate')
            return newPost
        }catch(e){
            console.error("Erro ao adicionar documento", e);
        }       
    },
    async updatePost({dispatch}, {id=null, title=null, content=null, collection}){
        try{
            let post  = new Post({id});
            await post.getPost()
            post.title = title;
            post.content = content;
            post.collection = collection;
            await post.updatePostFirebase();
            dispatch('afterUpdate')
        }catch(e){
            console.error("Erro ao atualizar documento", e);
        }
    },
    async removePost({dispatch}, {id=null}){
        try{
            let post  = new Post({id});
            await post.getPost()
            await post.deletePostFirebase();
            dispatch('afterUpdate')
        }catch(e){
            console.error("Erro ao deletar documento", e);
        }
    },
    async getPostsById(_, {id=null}){
        try{
            if (!id) return;
            let post  = new Post({id});
            await post.getPost()
            return post;
        }catch(e){ 
            console.error("Erro ao buscar post", e);
        }

    },
    async getLastReleases({commit}){
        try{
            const docRef = collection(db, "posts")
            const q = query(docRef, orderBy("createdAt"), where('collection', '==', 'posts'));
            const querySnapshot = await getDocs(q);
            let posts = []
            querySnapshot.forEach((doc) => {
                let post = doc.data()
                post.id = doc.id
                posts.push(post)
            });

            commit("setLastReleases", posts.reverse())
        }catch(e){
            console.error("Erro ao buscar posts", e);
        }
    },
    async getServicesPosts({commit}){
        try{
            const docRef = collection(db, "posts")
            const q = query(docRef, orderBy("createdAt"), where('collection', '==', 'services'));
            const querySnapshot = await getDocs(q);
            let posts = []
            querySnapshot.forEach((doc) => {
                let post = doc.data()
                post.id = doc.id
                posts.push(post)
            });

            commit("setServicesPosts", posts.reverse())
        }catch(e){
            console.error("Erro ao buscar posts", e);
        }
    }
}

const namespaced = true;

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};