import { createSSRApp, h } from 'vue'
import { renderToString } from 'vue/server-renderer'

import { store } from './store';
import router from "@/router";


import axios from 'axios'
import VueAxios from 'vue-axios'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import App from './App.vue';

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware]
    const context = { to, from, next, router, store }
    return middleware[0]({ ...context, nextMiddleware: middlewarePipeline(context, middleware, 1) })
  }
  return next()
})

// Função para executar vários middlewares em sequência
function middlewarePipeline(context, middleware, index) {
  const nextMiddleware = middleware[index]
  if (!nextMiddleware) return context.next
  return (...parameters) => {
    context.next(...parameters)
    const nextMidd = middlewarePipeline(context, middleware, index + 1)
    nextMiddleware({ ...context, next: nextMidd })
  }
}


const app = createSSRApp({
  render: () => h(App)
});

renderToString(app).then(() => {
})

app.use(store);
app.use(router)
app.use(VueAxios, axios)

router.isReady().then(() => {
  app.mount('#app');
});



