import { doc, setDoc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";
import {db} from "@/db/firebase";

class Post {
    constructor({id = null, title=null, content=null, createdAt=null, updatedAt=null, collection=null}) {
      this.id = id || this.generateId(title);
      this.title = title;
      this.content = content || "";
      this.createdAt = createdAt;
      this.updatedAt = updatedAt;
      this.collection = collection || 'posts'
    }

    async addPostFirebase(){
      return await setDoc(doc(db, 'posts', this.id), {...this});
    }

    async updatePostFirebase() {
      return await updateDoc(doc(db, 'posts', this.id), {...this});
    }
  
    async deletePostFirebase(){
      return await deleteDoc(doc(db, 'posts', this.id));
    }

    async getPost(){
      let post = await getDoc(doc(db, 'posts', this.id))
      this.id = post.id;

      let data = post.data();      
      if (!data) throw new Error('Post não encontrado!');
      this.title = data.title;
      this.content = data.content;
      this.createdAt = data.createdAt;
      this.updatedAt = data.updatedAt;
      this.collection = data.collection;
    }


    generateId(title){
      return title.toLowerCase().replaceAll(' ', '-').normalize("NFD")
    }
  }
  
export default Post;