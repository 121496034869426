import { collection, doc, setDoc } from "firebase/firestore"
import {db} from "@/db/firebase";

class Forms {
    constructor(formData) {
        this.id = Date.now();
        this.campaign = formData.campaign || this.id;
        this.formData = {...formData, readed: false};
    }

    async addFormFirebase(){
        const newForm = doc(collection(db, "forms"));
        await setDoc(newForm,  this.formData);
    }

    // async updateProductFirebase() {
    //   await updateDoc(doc(db, `forms`, `${this.campaign}`, this.id), this.formData);
    // }
  
    // async deleteProductFirebase(){
    //   return await deleteDoc(doc(db,`forms`, `${this.campaign}`, this.id));
    // }
  }
  
export default Forms;