import { createRouter, createWebHistory } from 'vue-router';
// Router
const LinePage = () => import('@/components/LinePage/LinePage.vue');
const Blog = () => import('@/components/App/Layout.vue');
const Index = () => import('@/components/App/Content/Pages/Index.vue');
const Post = () => import('@/components/App/Content/Pages/Post.vue');
const TextEditor = () =>
  import('@/components/App/Content/Pages/TextEditor.vue');
const PageNotPermission = () =>
  import('@/components/App/Content/Pages/403.vue');
const PageNotFound = () => import('@/components/App/Content/Pages/404.vue');


// Loja
const ManagerProducts = () =>
  import('@/components/App/Admin/Products/ManagerProducts.vue');
const ManagerCategory = () =>
  import('@/components/App/Admin/Categories/ManagerCategory.vue');
const ManagerRequest = () =>
  import('@/components/App/Admin/Requests/ManagerRequest.vue');
const Store = () =>
  import('@/components/App/Admin/Store/Store.vue');

const ChatbotConfig = () =>
  import('@/components/App/Content/Pages/ChatbotPage.vue');

// Forum
const ForumLayout = () => import('@/components/App/Forum/ForumLayout.vue');
const ForumIndex = () => import('@/components/App/Forum/ForumIndex.vue');
const TopicEditor = () => import('@/components/App/Forum/TopicEditor.vue');
const Threads = () => import('@/components/App/Forum/topics/Threads.vue');
import isLoggedIn from '../middleware/logged';
import isAdmin from '../middleware/isAdmin';
import expandMenu from '../middleware/expandMenu';

// INSTA BIO
const InstaBio = () => import('@/components/Links/InstaBio.vue');

const routes = [
  {
    path: '/',
    component: LinePage,
  },
  {
    path: '/plataform',
    component: Blog,
    middleware: [expandMenu],
    children: [
      {
        path: '',
        components: {
          plataform: Index,
        },
        middleware: [expandMenu],
      },
      {
        path: '/blog',
        redirect: () => {
          // the function receives the target route as the argument
          // we return a redirect path/location here.
          return '/post/consultoria-digital';
        },
        components: {
          plataform: Index,
        },
        middleware: [expandMenu],
      },
      {
        path: '/services',
        components: {
          plataform: Index,
        },
        middleware: [expandMenu],
      },
      {
        path: '/forum',
        components: {
          plataform: ForumLayout,
        },
        meta: {
          middleware: [expandMenu],
        },
        children: [
          {
            path: '',
            components: {
              forum: ForumIndex,
            },
            middleware: [expandMenu],
          },
          {
            path: '/newTopic/:topic',
            components: {
              forum: TopicEditor,
            },
            middleware: [expandMenu],
          },
          {
            path: '/threads/:id',
            components: {
              forum: Threads,
            },
            middleware: [expandMenu],
          },
        ],
      },
      {
        path: '/post/:id',
        components: {
          plataform: Post,
        },
        meta: {
          middleware: [expandMenu],
        },
      },
      {
        path: '/editor/:id?',
        components: {
          default: Blog,
          plataform: TextEditor,
        },
        meta: {
          middleware: [expandMenu, isLoggedIn, isAdmin],
        },
      },
      {
        path: '/403',
        components: PageNotPermission,
      },
      {
        path: '/404',
        components: PageNotFound,
      },

      {
        path: '/managerProducts',
        components: {
          plataform: ManagerProducts,
        },
        meta: {
          middleware: [isAdmin, expandMenu],
        },
      },
      {
        path: '/managerCategories',
        components: {
          plataform: ManagerCategory,
        },
        meta: {
          middleware: [isAdmin, expandMenu],
        },
      },
      {
        path: '/visualizeStore',
        components: {
          plataform: Store,
        },
        meta: {
          middleware: [isAdmin, expandMenu],
        },
      },
      {
        path: '/chatbot',
        components: {
          plataform: ChatbotConfig,
        },
        meta: {
          middleware: [isLoggedIn, isAdmin, expandMenu],
        },
      },

      {
        path: '/createRequest',
        components: {
          plataform: ManagerRequest,
        },
      },
    ],
  },
  {
    path: '/insta-bio',
    component: InstaBio,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
