<template>
  <div :class="getClass" v-if="show">
    <button
        type="button"
        class="btn-close"
        @click="show = false"
      ></button>
    <p>{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "ToastComponent",
  data() {
    return {
      error: false,
      show: false,
      description: "",
      timer: 4000,
    };
  },
  computed: {
    getClass() {
      if (this.error) {
        return "toast error";
      } else {
        return "toast sucess";
      }
    },
    getToastEvent() {
      return this.$store.getters["getToast"];
    },
  },
  watch: {
    getToastEvent(newValue) {
      this.show = true;
      this.error = newValue.error;
      this.description = newValue.description;
      setTimeout(() => {
        this.show = false;
      }, this.timer);
    },
  },
};
</script>

<style lang="scss">
.toast {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 15vh;
  background-color: rgb(19, 18, 18);
  bottom: 1rem;
  right: 1rem;
  color: #fff;
  z-index: 99999;
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  display: block !important;
  p {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  .btn-close{
    position: absolute;
    top: 10px;
    right: 10px;
    color: #fff;
  }
}

.error {
  background-color: rgb(230, 68, 68);
}

.sucess {
  background-color: rgb(98, 172, 83);
}

$small: 300px;
$medium: 1200px;


@media only screen and (max-width: 1000px) {
  .toast {
    width: 90vw;
    transform: translate(-50%, 0);
    top: 1rem;
    left: 50%;
  }
}
</style>