<template>
  <!-- Button trigger modal -->
  <!-- Modal -->

  <div class="container checkout-modal">
    <button type="button" class="btn-close" @click="closeModal"></button>
    <div class="row justify-content-center align-items-center">
      <div class="col-12 col-md-6 h-100">
        <h3 class="modal-title text-center">Comprar RMO's</h3>

        <!-- SELEÇÃO DE TOKENS -->
        <ConvertTokenReal
          :showWaning="true"
          @valueReal="($event) => setTotalValue($event)"
          class="mb-3 mt-3"
        />

        <div class="row justify-content-center align-items-center mt-2 mb-2">
          <h4 class="text-center">Total: R$:{{ getTotalInReal }}</h4>
        </div>

        <div class="row justify-content-center" v-if="loading">
          <div
            class="col-12 spinner-border text-primary"
            role="status"
            v-if="loading"
          >
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>

        <div class="row buy-buttons-area p-0 m-0" v-else>
          <div v-show="!showCreditModal">
            <div class="col d-flex justify-content-center p-0 m-0">
              <button
                type="submit"
                id="form-checkout__submit"
                class="btn btn-primary mt-3"
                @click="payWithCreditCard"
              >
                Pagar com cartão de crédito
              </button>
            </div>
            <div class="col d-flex justify-content-center p-0 m-0">
              <select-button-dual id="wallet_container"></select-button-dual>
            </div>
          </div>
          <div
            v-if="showCreditModal"
            class="col d-flex justify-content-center p-0 m-0"
          >
            <button
              type="submit"
              id="form-checkout__submit"
              class="btn btn-primary mb-3"
              @click="($event) => (showCreditModal = false)"
            >
              Voltar
            </button>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6" v-show="showCreditModal">
        <!-- INICIO FORM DE PAGAMENTO -->
        <form id="form-checkout">
          <div id="form-checkout__cardNumber" class="container"></div>
          <div id="form-checkout__expirationDate" class="container"></div>
          <div id="form-checkout__securityCode" class="container"></div>
          <input type="text" id="form-checkout__cardholderName" />
          <select id="form-checkout__issuer" class="form-select"></select>
          <select id="form-checkout__installments" class="form-select"></select>
          <select
            id="form-checkout__identificationType"
            class="form-select"
          ></select>
          <input type="text" id="form-checkout__identificationNumber" />
          <input type="email" id="form-checkout__cardholderEmail" />

          <button
            type="submit"
            id="form-checkout__submit"
            class="btn btn-primary mt-3"
            :disabled="loading"
          >
            Comprar agora
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/config/api';
import ConvertTokenReal from '@/components/App/Content/Utils/ConvertTokenReal.vue';
let cardForm;
// eslint-disable-next-line no-undef
let mp;

export default {
  name: 'CheckoutPage',
  components: {
    ConvertTokenReal,
  },
  data() {
    return {
      showModal: false,
      cardNumber: '',
      cardHolder: '',
      expirationMonth: '',
      expirationYear: '',
      cvv: '',
      isValidCardNumber: true,
      isValidExpirationDate: true,
      isValidCVV: true,
      getTotalInReal: 10,
      loading: true,
      showCreditModal: false,
    };
  },
  computed: {
    getUser() {
      return this.$store.getters['user/getUser'];
    },
  },
  mounted() {
    const script = document.createElement('script');
    script.src = 'https://sdk.mercadopago.com/js/v2';
    document.head.appendChild(script);
    script.addEventListener('load', () => {
      // eslint-disable-next-line no-undef
      mp = new MercadoPago('TEST-6ed6ff80-9b1e-4557-85c1-d38882623690');
      this.loadingMercadoPagoButton();
    });
  },
  methods: {
    closeModal() {
      this.$store.dispatch('showCheckout');
    },
    getAmout() {
      return this.getTotalInReal.toString();
    },
    async loadingMercadoPagoButton() {
      try {
        this.loading = true;
        const response = await api.post(
          '/payment-createPreference',
          { price: Number(this.getTotalInReal), user: this.getUser },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        mp.bricks().create('wallet', 'wallet_container', {
          initialization: {
            preferenceId: response.data,
          },
          customization: {
            texts: {
              valueProp: 'convenience',
            },
          },
        });
        this.loading = false;
      } catch (error) {
        console.error(error);
        this.loading = false; // Trate o erro, se necessário
        this.$store.dispatch('setToast', {
          error: true,
          description: 'Não foi possível realizar a compra!',
        });
      }
    },
    payWithCreditCard() {
      this.showCreditModal = true;
      this.createForm();
    },
    createForm() {
      this.resetCard();
      cardForm = mp.cardForm({
        amount: this.getAmout(),
        iframe: true,
        form: {
          id: 'form-checkout',
          cardNumber: {
            id: 'form-checkout__cardNumber',
            placeholder: 'Número do cartão',
          },
          expirationDate: {
            id: 'form-checkout__expirationDate',
            placeholder: 'MM/YY',
          },
          securityCode: {
            id: 'form-checkout__securityCode',
            placeholder: 'Código de segurança',
          },
          cardholderName: {
            id: 'form-checkout__cardholderName',
            placeholder: 'Titular do cartão',
          },
          issuer: {
            id: 'form-checkout__issuer',
            placeholder: 'Banco emissor',
          },
          installments: {
            id: 'form-checkout__installments',
            placeholder: 'Parcelas',
          },
          identificationType: {
            id: 'form-checkout__identificationType',
            placeholder: 'Tipo de documento',
          },
          identificationNumber: {
            id: 'form-checkout__identificationNumber',
            placeholder: 'Número do documento',
          },
          cardholderEmail: {
            id: 'form-checkout__cardholderEmail',
            placeholder: 'E-mail',
          },
        },
        callbacks: {
          onFormMounted: (error) => {
            if (error)
              return console.warn('Form Mounted handling error: ', error);
            console.log('Form mounted');
          },
          onSubmit: async (event) => {
            this.loading = true;
            event.preventDefault();
            const {
              paymentMethodId: payment_method_id,
              issuerId: issuer_id,
              cardholderEmail: email,
              amount,
              token,
              installments,
              identificationNumber,
              identificationType,
            } = cardForm.getCardFormData();

            const payload = {
              token,
              issuer_id,
              payment_method_id,
              transaction_amount: Number(amount),
              installments: Number(installments),
              description: 'Compra de tokens RMO',
              additional_info: {
                items: [
                  {
                    id: String(Date.now()),
                    title: 'Compra de tokens RMO',
                    description: 'Compra de tokens RMO',
                    quantity: 10,
                    unit_price: 0.6,
                  },
                ],
              },
              payer: {
                email,
                identification: {
                  type: identificationType,
                  number: identificationNumber,
                },
              },
              capture: false,
              user: this.getUser,
            };

            api
              .post('/payment-process_payment', payload, {
                headers: {
                  'Content-Type': 'application/json',
                },
              })
              .then((response) => {
                // Lógica para lidar com a resposta da solicitação
                console.log(response.data);

                this.$store.dispatch('setToast', {
                  error: false,
                  description:
                    'Compra efetuada com sucesso. Em breve seu novo saldo será atualizado!',
                });

                setTimeout(() => {
                  location.reload();
                  this.loading = false; // Atualize o estado do componente, se necessário
                }, 3000);
              })
              .catch((error) => {
                console.error(error);
                this.loading = false; // Trate o erro, se necessário
                this.$store.dispatch('setToast', {
                  error: true,
                  description: 'Não foi possível realizar a compra!',
                });
              });
          },
          onFetching: (resource) => {
            console.log('Fetching resource: ', resource);

            // Animate progress bar
            const progressBar = document.querySelector('.progress-bar');
            progressBar.removeAttribute('value');

            return () => {
              progressBar.setAttribute('value', '0');
            };
          },
        },
      });
    },
    setTotalValue(value) {
      this.loading = false;
      this.getTotalInReal = value;
      this.createForm();
      let div = document.getElementById('wallet_container');
      div.innerHTML = '';
      this.loadingMercadoPagoButton();
    },
    resetCard() {
      if (cardForm) cardForm.unmount();
    },
  },
};
</script>

<style lang="scss" scoped>
.checkout-modal {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  position: fixed;
  z-index: 9998 !important;
  padding: 5% 5% !important;
  overflow: auto;
  top: 0;
  left: 0;

  .btn-close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 999;
    cursor: pointer;
  }
}

.buy-buttons-area {
  button {
    min-width: 100%;
    min-width: 280px;
    min-height: 48px;
    max-height: 48px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.row {
  width: 100%;
  margin: 0;
}

#form-checkout {
  display: flex;
  flex-direction: column;

  div,
  input,
  select {
    width: 100%;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    height: 50px;
    margin-bottom: 5px;
  }

  button {
    min-width: 100%;
    min-width: 280px;
    min-height: 48px;
    max-height: 48px;
    font-family: Arial, Helvetica, sans-serif;
  }
}

.container {
  height: 18px;
  display: inline-block;
  border: 1px solid rgb(118, 118, 118);
  border-radius: 2px;
  padding: 1px 2px;
}
</style>
