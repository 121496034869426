<template>
  <div
    :class="{ 'chatbot-container': true, minimized: isMinimized }"
    :style="{ zIndex: zIndex }"
  >
    <div class="chatbot-header" @click="toggleMinimize">
      <h5>Chatbot</h5>
      <button class="btn btn-primary btn-sm">Abrir</button>
    </div>
    <div class="chatbot-messages">
      <div
        v-for="message in messages"
        :key="message.id"
        :class="{ 'chatbot-message': true, 'bot-message': message.isBot }"
      >
        <p v-html="formattedText(message.content)"></p>
      </div>
    </div>
    <div class="chatbot-input">
      <input
        type="text"
        v-model="userMessage"
        @keydown.enter="sendMessage"
        placeholder="Digite uma mensagem..."
      />
      <button class="btn btn-primary" @click="sendMessage">Enviar</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ChatBot',
  data() {
    return {
      isMinimized: true,
      zIndex: 9999,
      messages: [],
      userMessage: '',
      messageId: 0,
    };
  },
  computed: {
    
  },
  methods: {
    toggleMinimize() {
      this.isMinimized = !this.isMinimized;
    },
    async sendMessage() {
      const messageContent = this.userMessage.trim();
      this.messages.push({
        id: Date.now(),
        content: messageContent,
        isBot: false,
      });
      if (messageContent !== '') {
        await axios
          .post('http://localhost:3000/predict', { text: messageContent })
          .then((response) => {
            console.log(response.data)
            const message = response.data.text;
            this.replyToUser(message);
            this.userMessage = '';
            this.scrollToBottom();
          })
          .catch((error) => {
            console.error('Erro:', error);
          });
      }
    },
    replyToUser(message) {
      // Simulando resposta do bot
      setTimeout(() => {
        this.messages.push({
          id: this.messageId++,
          content: 'Resposta do bot: ' + message,
          isBot: true,
        });
        this.scrollToBottom();
      }, 500);
    },
    scrollToBottom() {
      setTimeout(() => {
        const container = this.$el.querySelector('.chatbot-messages');
        container.scrollTop = container.scrollHeight;
      }, 100);
    },
    formattedText(text) {
      return text.replace(/\n/g, '<br>');
    },
  },
};
</script>

<style lang="scss" scoped>
.chatbot-container {
  position: fixed;
  bottom: 0px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  transition: height 0.3s ease;
}

.minimized .chatbot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.minimized .chatbot-messages,
.minimized .chatbot-input {
  display: none;
}

.chatbot-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.chatbot-messages {
  height: 400px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding-right: 10px;
}

.chatbot-message {
  margin-bottom: 5px;
  padding: 5px;
  color: black;

  p {
    font-size: 0.8rem !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.bot-message {
  background-color: #f0f0f0;
}

.chatbot-input {
  display: flex;
  align-items: center;
}

.chatbot-input input {
  flex: 1;
  margin-right: 10px;
}
</style>
