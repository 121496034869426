<template>
  <div class="row justify-content-center">
    <div class="alert alert-warning text-center" role="alert" v-if="showWaning">
      Valor mínimo de {{ 10 }} reais
    </div>

    <div class="row">
      <div class="col-6 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center me-2">
          {{ operation == "toToken" ? "Valor em reais" : "Valor em tokens" }}
        </div>
      </div>
      <div class="col-6 d-flex align-items-center justify-content-center">
        <div class="d-flex align-items-center ms-2">
          {{ operation == "toToken" ? "Valor em tokens" : "Valor em reais" }}
        </div>
      </div>
    </div>

    <div class="col-12 d-flex align-items-center m-0 p-0">
      <div class="input-group">

        <input @input="handleInput" v-model="input" type="text" class="form-control text-center"
          placeholder="Valor em reais" />

        <div class="input-group-text">
          <button class="btn btn-primary" @click="convertTokenToReal">
            <i class="fas fa-exchange-alt"></i>
          </button>
        </div>

        <input v-model="result" type="text" class="form-control text-center" placeholder="Digite o valor em token"
          disabled />

      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';

export default {
  props: {
    showWaning: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      operation: "toToken",
      input: 10,
      result: (10 / 0.06).toFixed(2),
      disable: false,
    };
  },
  watch: {

  },
  methods: {
    convertToNum(num) {
      let formattedNumber = num.toLocaleString("pt-BR");
      return formattedNumber;
    },
    convertTokenToReal() {
      if (this.operation == "toToken") {
        this.operation = "toReal";
        this.input = 1;
        this.result = this.convertToNum((1 * 0.06).toFixed(2));
        this.$emit("valueReal", this.result)
      } else {
        this.operation = "toToken";
        this.input = 1;
        this.result = this.convertToNum((1 / 0.06).toFixed(2));
        this.$emit("valueReal", this.input)
      }
    },
    handleInput: _.debounce(function () {
      if (this.operation == "toToken") {
        this.result = this.convertToNum(this.input / 0.06);
        this.$emit("valueReal", this.input)
      } else {
        this.result = this.convertToNum(this.input * 0.06);
        this.$emit("valueReal", this.result)
      }
    }, 1000)
  },
};
</script>

<style lang="scss" scoped>
.row {
  width: 100%;
}
</style>