import Forms from '@/class/Forms';
import IPO from "@/class/Ipo";
import User from "@/class/User";
const state = {
};

const mutations = {
};

const getters = {
};

const actions = {
	async saveFormToDataBase(_, form) {
		let newForm = new Forms(form);
		return await newForm.addFormFirebase();
	},
	async createIPO({ rootGetters }) {
		let newIPO = new IPO({ stage: 1, name: "Pré-venda SAT's", totalSupply: 1000000 });
		let user = new User();
		await user.getUser(rootGetters['user/getUser'].id);
		await newIPO.addIpoFirebase(user);
	},
	async getIpoOpen() {
		let ipo = new IPO({ id: "1684984281540" });
		await ipo.getIpo();
		return ipo;
	},

}

export default {
	state,
	mutations,
	getters,
	actions,
	namespaced: true,
};
