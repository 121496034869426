import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from "@/db/firebase";
import api from '@/config/api';


const state = {
    allCategories: [],
    downloadImageBuffer: null,
    percentage: null,
};

const mutations = {
    setAllCategories(state, allCategories) {
        state.allCategories = allCategories;
    },
    addCategory(state, data) {
        state.allCategories.push(data);
    },
    updateCategorie(state, data) {
        const categorieIndex = state.allCategories.findIndex((category) => category.id === data.id);
        state.allCategories[categorieIndex] = data;
    },
    deleteCategorie(state, data) {
        const categorieIndex = state.allCategories.findIndex((category) => category.id === data.id);
        state.allCategories.splice(categorieIndex, 1);
    },
};

const getters = {
    getAllCategories(state) {
        return state.allCategories;
    },
};

const actions = {
    async startListenerAllCategories({ commit }) {
        commit('setAllCategories', []);
        const q = query(collection(db, "categories"));
        onSnapshot(q, (snapshot) => {
            snapshot.docChanges().forEach((change) => {
                let doc = change.doc.data();
                doc.id = change.doc.id;
                if (change.type === "added") {
                    commit('addCategory', doc);
                }
                if (change.type === "modified") {
                    commit('updateCategorie', doc);
                }
                if (change.type === "removed") {
                    commit('deleteCategorie', doc);
                }
            });
        });
    },
    async addCategorie(_, { name }) {
        try {
            return await api.post('user-addCategorie', {
                name
            })
        } catch (e) {
            throw new Error("Erro ao adicionar categoria. Tente novamente mais tarde.");
        }
    },
    async removeCategorie(_, id) {
        try {
            return await api.post('user-removeCategorie', {
                id
            })
        } catch (e) {
            throw new Error("Erro ao adicionar categoria. Tente novamente mais tarde.");
        }
    }

}

export default {
    state,
    mutations,
    getters,
    actions,
    namespaced: true,
};
