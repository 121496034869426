// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA7R8plYB4rCHtm8jBOkdsZNscw-OJCyCw",
  authDomain: "webstore-ea2cb.firebaseapp.com",
  databaseURL: "https://webstore-ea2cb-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "webstore-ea2cb",
  storageBucket: "webstore-ea2cb.appspot.com",
  messagingSenderId: "92951479341",
  appId: "1:92951479341:web:35a8c367468c1b26b624a4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

