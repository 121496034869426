import { db } from "@/db/firebase";
import { doc, setDoc, updateDoc, deleteDoc, getDoc } from "firebase/firestore";

class IPO {
    constructor({id = "", stage="", name="", totalSupply=""}) {
      this.id = id || Date.now().toString();
      this.stage = stage;
      this.name = name;
      this.totalSupply = totalSupply;
      this.totalSold = 0;
      this.stakingBalance = 0;
      this.maxSupply = 0;
    }

    async addIpoFirebase(user) {
      try {        
        if(user.role !== "admin") throw new Error("User has not admin permissions")
        // Adicione os dados do usuário ao Firestore
        let ipoDoc = await setDoc(doc(db, 'ipos', this.id), { ...this });
        return ipoDoc
      } catch (error) {
        console.error('Erro ao criar IPO:', error.code, error.message);
        return error;
      }
    }

    async buy(user, quantity){
      if(this.totalSupply - quantity < 0) throw new Error('Can not buy this quantity. totalSupply limit was ranch!');
      this.totalSold += quantity;
      this.totalSupply -= quantity;
      await this.updateIpoFirebase();
      return await user.updateUserBalance(quantity);
    }

    async updateIpoFirebase() {
      return await updateDoc(doc(db, 'ipos', this.id), {...this});
    }
  
    async deleteIpoFirebase(user){
      if(user.role !== "admin") throw new Error("User has not admin permissions")
      return await deleteDoc(doc(db, 'ipos', this.id));
    }

    async getIpo(){
      let ipos = await getDoc(doc(db, 'ipos', this.id))
      this.id = ipos.id;

      let data = ipos.data();      
      this.stage = data.stage;
      this.name = data.name;
      this.totalSupply = data.totalSupply;
      this.totalSold = data.totalSold;
      this.stakingBalance = data.stakingBalance;
      this.maxSupply = data.maxSupply;
    }
  }
  
export default IPO;