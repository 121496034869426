
export default function log({ next, store }) {
  setTimeout(() => {
    let divContent = document.getElementById('content');
    divContent.scrollTop = 0;
    if (store.state.expandRightMenu) store.dispatch('expandRightMenu');
    if (store.state.expandLeftMenu) store.dispatch('expandLeftMenu');
  }, 100);

  return next();
}